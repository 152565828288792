<script setup>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import { onMounted, ref, computed } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import { useStore } from 'vuex';

const store = useStore();

const title = ref('Camera Sort');
const items = ref([
  {
    text: "Home"
  },
  {
    text: "Camera"
  },
  {
    text: "Sort",
    active: true
  }
]);

const loading = computed(() => store.state.globals.loading);
const cameraSort = computed(({
    get() {
      return store.state.camera.cameraSort;
    },
    set(value) {
      store.commit('camera/UPDATE_CAMERA_SORT', value);
    }
}));

const lgus = computed(() => store.state.lgu.dropdownLGUs);
const notification = computed(() => store.state.notification);

const getAllCameraSort = () => {
  store.dispatch('camera/getCameraSort')
}

const updateCameraSort = () => {
  store.dispatch('camera/updateCameraSort');
}

const getLGUName = (id) => {
    if (lgus.value.length > 0) {
      const lgu = lgus.value.find((el) => el.id === id);
      return lgu.name;
    }
    return 'N/A';
}

onMounted(() => {
  store.dispatch('lgu/getDropdownLGUs');
  getAllCameraSort();
});

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col lg-12">
            <b-overlay :show="loading">
              <div class="card">
                <div class="card-header">
                      <h4 class="card-title mb-0">Sort Camera Queue</h4>
                      <small>This will affect the queueing (input and verify). Get queue records depending on how the camera is sorted.</small>
                  </div>
                  <div class="card-body">
                    <div v-if="notification && notification.messages.length > 0">
                          <b-alert v-for="(message, index) in notification.messages" :key="index"
                              show variant="danger" class="fade show" role="alert">
                              {{ message }}
                          </b-alert>
                      </div>
                      
                      <div class="row">
                        <div class="col-md-10">
                          <b-list-group v-if="cameraSort && cameraSort.length > 0">
                            <VueDraggableNext 
                              v-model="cameraSort" 
                              @change="updateCameraSort"
                            >
                              <transition-group
                                type="transition" 
                                name="flip-list"
                              >
                                <b-list-group-item 
                                  v-for="item in cameraSort"
                                  :key="item.id"
                                  tag="button"
                                  class="list-group-item-action d-flex justify-content-between align-items-center"
                                  aria-current="true"
                                >
                                  <span>
                                    <i class="mdi mdi-drag-horizontal mr-3"></i>
                                    Camera ID: {{ item.id }} - {{ item.location }} (<strong>{{ getLGUName(item.lgu_id) }}</strong>)
                                  </span>
                                  <b-badge :variant="item.is_enabled ? 'success' : 'danger'">{{ item.is_enabled ? 'Enabled' : 'Disabled' }}</b-badge>
                                </b-list-group-item>
                              </transition-group>
                            </VueDraggableNext>
                          </b-list-group>
                          <div v-else>
                            <h4>No camera to sort</h4>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
            </b-overlay>
        </div>
    </div>
  </Layout>
</template>